import { formatDate } from "@/utils"
import { graphql, Link, PageProps } from "gatsby"
import React from "react"
import "twin.macro"

import { Article, BackgroundImage, Headings, Section } from "@/atoms"
import { BreadCrumbs, Layout, NextSEO, Pagination, SEO } from "@/components"
import { NewsTemplateQuery, SitePageContext } from "@/gatsby-graphql"
import tw from "twin.macro"


export type NewsTemplateProps = PageProps<NewsTemplateQuery>

function News({ data, pageContext, location }: NewsTemplateProps) {
  const { currentPageNum, pageCount } = pageContext
  return (
    <Layout>
      {/* <SEO
        title={
          currentPageNum === 1
            ? "更新情報一覧"
            : `更新情報一覧-${currentPageNum}ページ目`
        }
        description={
          currentPageNum === 1
            ? "更新情報一覧"
            : `更新情報一覧-${currentPageNum}ページ目`
        }
      /> */}
      <NextSEO
        type="Article"
        title={
          currentPageNum === 1 ? "更新情報一覧" : `更新情報一覧-${currentPageNum}ページ目`
        }
        description={
          currentPageNum === 1 ? "更新情報一覧" : `更新情報一覧-${currentPageNum}ページ目`
        }
        url={`/news`}
        breadCrumbs={[
          { title: "更新情報", uri: "/news" },
          { title: currentPageNum === 1 ? "更新情報一覧" : `更新情報一覧-${currentPageNum}ページ目`, },
        ]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>更新情報</Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs
          breadCrumbs={[
            {
              title:
                currentPageNum === 1
                  ? "更新情報一覧"
                  : `更新情報一覧-${currentPageNum}ページ目`,
            },
          ]}
        />
        <Section css={{ ...tw`` }}>
          {data.allWpNews.edges.map(edge => {
            return (
              <article css={{
                ...tw`py-2 border-t last-of-type:(border-b)`,
                '@tablet': { ...tw`py-4` }
              }}>
                <div css={{ ...tw`space-x-2` }}>
                  <time>
                    {formatDate(edge.node.news.newsDate, "YYYYMMDD", "YYYY年M月D日", true)}
                  </time>
                  <span css={{ ...tw`` }}>{edge.node.news.newsCategoryAcf?.name ? edge.node.news.newsCategoryAcf?.name : "お知らせ"}</span>
                </div>
                <Headings variant='h4' as='h1' >
                  <Link
                    to={
                      `/news/${edge?.node?.news?.newsTitle}`
                      // edge?.node?.news?.newsSlug
                      //   ? `/news/${edge?.node?.news?.newsSlug}`
                      //   : `/news/${edge?.node?.news?.newsTitle}`
                    }
                  >
                    {edge.node.news.newsTitle}
                  </Link>
                </Headings>
              </article>
            )
          })}
        </Section>
        {pageCount > 1 && (
          <Pagination pageContext={pageContext} slug={`news`} />
        )}
      </Article>
    </Layout>
  )
}

export default News

/* eslint no-undef: "off" */
export const newsQuery = graphql`
  query NewsTemplate($skip: Int!, $limit: Int!) {
    allWpNews(
      sort: { fields: news___newsDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          databaseId
          news {
            newsDate
            newsTitle
            newsSlug
            newsCategoryAcf {
              name
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [WEBP]
        )
      }
    }
  }
`
